import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ButtonUp from "../utilities/ButtomUp";
import slugify from "slugify";
import Comments from "../components/Comments";
import EditorPosts from "../components/EditorPosts";
import { INLINES, BLOCKS, MARKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BiCommentDetail } from "@react-icons/all-files/bi/BiCommentDetail";
import { FaFacebookSquare } from "@react-icons/all-files/fa/FaFacebookSquare";
import { FaWhatsappSquare } from "@react-icons/all-files/fa/FaWhatsappSquare";
import { FaLine } from "@react-icons/all-files/fa/FaLine";
import { IoLeafOutline } from "@react-icons/all-files/io5/IoLeafOutline";
import { ImQuotesLeft } from "@react-icons/all-files/im/ImQuotesLeft";
import { ImQuotesRight } from "@react-icons/all-files/im/ImQuotesRight";
import { WhatsappShareButton, LineShareButton } from "react-share";
import ShareLink from "react-facebook-share-link";
import Seo from "../components/Seo";
import BannerSide from "../components/BannerSide";

const Bold = ({ children }) => <span className="font-headlao">{children}</span>;
const Italic = ({ children }) => <span className="italic">{children}</span>;
const Underline = ({ children }) => (
  <span className="underline underline-offset-4">{children}</span>
);

const Text = ({ children }) => (
  <p className="text-base my-1 font-bodylao leading-relaxed">{children}</p>
);
const Heading = ({ children }) => (
  <h1 className="text-2xl my-1 font-headlao leading-relaxed">{children}</h1>
);
const SubHeading = ({ children }) => (
  <h2 className="text-xl my-1 font-headlao leading-relaxed">{children}</h2>
);

const UlList = ({ children }) => (
  <ul className="list-disc text-base my-1 translate-x-4 ">{children}</ul>
);
const OlList = ({ children }) => (
  <ol className="list-decimal text-base my-1 translate-x-4 ">{children}</ol>
);
const Hr = () => (
  <hr className="bg-stone-300 h-[3px] rounded-full w-[90%] mb-5 mt-1" />
);
const Quote = ({ children }) => (
  <div className="my-8 bg-lime-100/40 py-2 px-4 rounded-lg flex">
    <span>
      <ImQuotesLeft className="text-xs mt-2 mr-2" />
    </span>
    <span className="font-jarkatar font-semibold italic text-2xl text-center">
      {children}
    </span>
    <span className="flex flex-col justify-end items-end">
      <ImQuotesRight className="text-xs mb-2 ml-2 " />
    </span>
  </div>
);

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    [MARKS.ITALIC]: (text) => <Italic>{text}</Italic>,
    [MARKS.UNDERLINE]: (text) => <Underline>{text}</Underline>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.HEADING_1]: (node, children) => <Heading>{children}</Heading>,
    [BLOCKS.HEADING_2]: (node, children) => <SubHeading>{children}</SubHeading>,
    [BLOCKS.HR]: (node) => <Hr />,
    [BLOCKS.UL_LIST]: (node, children) => <UlList>{children}</UlList>,
    [BLOCKS.OL_LIST]: (node, children) => <OlList>{children}</OlList>,
    [BLOCKS.QUOTE]: (node, children) => <Quote>{children}</Quote>,
    [INLINES.HYPERLINK]: (node, children) => {
      const { uri } = node.data;
      return (
        <a href={uri} className="underline text-blue-600 hover:text-blue-400">
          {children}
        </a>
      );
    },
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { gatsbyImageData } = node.data.target;
      if (!gatsbyImageData) {
        return null;
      }
      return (
        <>
          <GatsbyImage
            image={gatsbyImageData}
            className="rounded shadow-sm my-3 w-full md:w-[70%]"
            alt="post-images"
          />
        </>
      );
    },
  },
};

const aniSmooth = "transition-all duration-[0.3s] ease-in-out";

const PostTemplate = ({ data }) => {
  const {
    title,
    subPath,
    date,
    description: { description },
    richtext,
    image: {
      file: { url },
      gatsbyImageData,
    },
  } = data.contentfulPost;
  const pathToImage = getImage(gatsbyImageData);
  const slug = slugify(subPath, { lower: true });
  const seoImg = url.substring(2);
  // console.log(description);
  // console.log(`https://${seoImg}`);

  return (
    <div className="w-full relative overflow-hidden bg-stone-50">
      <Seo
        title={title}
        pathname={slug}
        image={`https://${seoImg}`}
        description={String(description)}
      />
      <div id="top" />
      <Navbar className="relative z-10" />
      <div className="relative z-20 flex flex-col justify-center items-center -translate-y-3 lg:-translate-y-0 ">
        <div className="w-full 2xl:w-[1440px] px-4 md:px-14 h-full flex flex-col justify-start items-start">
          <div className="flex flex-col lg:flex-row w-full">
            <div className="w-full lg:w-[70%]">
              {/* post content */}
              <div className="flex w-full lg:w-[90%] flex-col">
                {/* COVER & TITLE */}
                <div className="mb-6 relative">
                  <div
                    className={`${aniSmooth} object-contain relative z-0 w-full h-full`}
                  >
                    <GatsbyImage
                      image={pathToImage}
                      alt={title}
                      className={`${aniSmooth} object-contain h-auto w-full md:w-[70%] brightness-[100%] rounded shadow-sm`}
                    />
                  </div>
                  <div className="z-10 mt-4 font-bold text-stone-900 font-headlao text-2xl text-left">
                    {title}
                  </div>
                  <div className="z-10 text-medium mt-2 text-stone-900 font-jarkatar text-sm flex justify-start items-center">
                    <div className="h-[2px] w-[20px] bg-stone-300 mr-2"></div>
                    <div>{date}</div>
                  </div>
                  <div className="z-10 mt-3 font-jarkatar text-stone-400 font-normal text-sm lg:text-base text-left flex justify-start items-center">
                    Share now
                    <ShareLink link={`https://www.econewslaos.com/${slug}`}>
                      {(link) => (
                        <a href={link} target="_blank" rel="noreferrer">
                          <FaFacebookSquare className="text-blue-800 opacity-90 mx-2 text-3xl hover:-translate-y-0.5 transition-all duration-300" />
                        </a>
                      )}
                    </ShareLink>
                    <WhatsappShareButton
                      url={`https://www.econewslaos.com/${slug}`}
                    >
                      <FaWhatsappSquare className="text-green-500 opacity-90 mr-2 text-3xl hover:-translate-y-0.5 transition-all duration-300" />
                    </WhatsappShareButton>
                    <LineShareButton
                      url={`https://www.econewslaos.com/${slug}`}
                    >
                      <FaLine className="text-green-600 opacity-90 mr-2 text-3xl hover:-translate-y-0.5 transition-all duration-300" />
                    </LineShareButton>
                  </div>
                </div>
                {/* description */}
                <div className="mb-6 mt-2 font-bodylao text-lg flex justify-start items-start">
                  <span className="text-3xl mr-3">
                    <IoLeafOutline />
                  </span>
                  {description}
                </div>
                {/* RICH TEXT */}
                <div>{richtext && renderRichText(richtext, options)}</div>
              </div>
              {/* post content */}
            </div>

            <div className="hidden lg:flex w-[30%] mt-10 lg:mt-0">
              <div className="w-full flex flex-col justify-start items-start">
                <div className="font-cardo font-bold text-3xl text-stone-900 mb-5 flex">
                  <div className="w-[7px] h-[35px] bg-green-120 opacity-80 mr-4"></div>
                  <div className="font-cardo font-bold text-3xl text-stone-900 cursor-default flex justify-center items-center">
                    Our Sponsor
                    <div className="flex justify-center items-center">
                      <div className="h-[2px] w-[20px] bg-stone-200 ml-3"></div>
                      <div className="bg-stone-400 font-jarkatar text-xs text-white px-2 py-0.5 rounded ml-3">
                        Ad
                      </div>
                    </div>
                  </div>
                </div>

                <BannerSide />

                <div className="font-cardo font-bold text-3xl text-stone-900 mb-0 lg:mb-4 mt-8 flex border-b-2 border-stone-300 w-full pb-6">
                  <div className="w-[7px] h-[35px] bg-green-120 opacity-80 mr-4"></div>
                  <div className="font-cardo font-bold text-3xl text-stone-900 cursor-default flex justify-center items-center">
                    Related Articles
                  </div>
                </div>
                <div className="w-full lg:none mt-6 md:mt-3">
                  <EditorPosts />
                </div>
              </div>
            </div>
          </div>

          <div className="w-full pt-6 mt-10 pb-12 border-t-[3px] border-stone-300/60 flex flex-col ">
            <div className="flex font-cardo font-bold text-lg lg:text-2xl mb-6">
              See what people say about this article
              <BiCommentDetail className="ml-2 text-2xl lg:text-3xl" />
            </div>
            <div className="w-full lg:w-[70%]">
              <Comments />
            </div>

            <div className="flex lg:hidden w-full mt-12 lg:mt-0">
              <div className="w-full flex flex-col justify-start items-start">
                <div className="font-cardo font-bold text-3xl text-stone-900 mb-5 flex">
                  <div className="w-[7px] h-[35px] bg-green-120 opacity-80 mr-4"></div>
                  <div className="font-cardo font-bold text-3xl text-stone-900 cursor-default flex justify-center items-center">
                    Our Sponsor
                    <div className="flex justify-center items-center">
                      <div className="h-[2px] w-[20px] bg-stone-200 ml-3"></div>
                      <div className="bg-stone-400 font-jarkatar text-xs text-white px-2 py-0.5 rounded ml-3">
                        Ad
                      </div>
                    </div>
                  </div>
                </div>

                <BannerSide />

                <div className="font-cardo font-bold text-3xl text-stone-900 mb-0 lg:mb-4 mt-8 flex border-b-2 border-stone-300 w-full pb-6">
                  <div className="w-[7px] h-[35px] bg-green-120 opacity-80 mr-4"></div>
                  <div className="font-cardo font-bold text-3xl text-stone-900 cursor-default flex justify-center items-center">
                    Related Articles
                  </div>
                </div>
                <div className="w-full lg:none mt-6 md:mt-3">
                  <EditorPosts />
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <ButtonUp link={`${slug}/#top`} />
    </div>
  );
};

export const query = graphql`
  query getPost($subPath: String) {
    contentfulPost(subPath: { eq: $subPath }) {
      title
      subPath
      date(formatString: "DD MMMM, YYYY")
      richtext {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData
          }
        }
      }
      description {
        description
      }
      id
      image {
        file {
          url
        }
        gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
      }
    }
  }
`;

export default PostTemplate;
